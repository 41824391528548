<template>
  <div>
    <v-app-bar app flat color="primary">
      <div class="d-flex justify-start">
        <v-combobox v-if="isadmin" class="d-flex toolbarcombo" v-model="selectedproject" :items="projectitems"
          item-value="id" item-text="name" dense solo></v-combobox>
        <v-btn v-if="isadmin" @click.prevent="shownewprojectdialog()" class="lightbutton ml-3"><v-icon class="mr-1">fas
            fa-pencil-ruler</v-icon>Nytt projekt</v-btn>
        <v-btn v-if="isadmin && projectitems.length > 0" @click.prevent="showdeleteprojectdialog = true"
          class="lightbutton ml-3"><v-icon class="mr-1">fas fa-pencil-ruler</v-icon>Ta bort projekt</v-btn>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex justify-end align-center">
        <img class="logo" src="./../assets/PLANIX_Text.svg" />
        <v-btn to="logout" text class="logoutbutton mr-12">Logga ut&nbsp;<i class="fas fa-sign-out-alt"></i></v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer app color="#505050" width="90">
      <div class="toolbarhome d-flex justify-center">
        <img src="./../assets/Planix_Houseicon.svg" />
      </div>
      <v-col class="d-flex justify-center">
        <v-btn icon class="iconbutton">
          <v-flex>
            <v-icon>mdi-folder</v-icon>
            <span>Dokument</span>
          </v-flex>
        </v-btn>
      </v-col>
      <template v-slot:append>
        <v-col class="d-flex justify-center">
          <v-btn icon class="iconbutton" href="https://byggingenjorsbyran.se/kontakt/" target="_blank">
            <v-flex>
              <v-icon>mdi-help-circle</v-icon>
              <span>Kontakt</span>
            </v-flex>
          </v-btn>
        </v-col>
      </template>
    </v-navigation-drawer>
    <v-main>
      <v-container class="projectarea" pa-0 info>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-dialog v-model="showprojectdialog" persistent max-width="500px">
      <v-card :loading="loading">
        <template slot="progress">
          <v-progress-linear color="red" indeterminate></v-progress-linear>
        </template>
        <v-form ref="projectform" v-model="validproject">
          <v-card-title>
            <span class="headline">Skapa nytt projekt...</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="8">
                  <v-text-field label="Projektnummer" ref="projno" v-model="projectnumber" :rules="projectnumberRules"
                    required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <v-text-field label="Lösenord" v-model="password" :rules="passwordRules" required
                    hint="(Fastighetsbeteckning -siffra)"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox color="secondary" hide-details label="Generera standardmappar"
                    v-model="generatedefault"></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="smallbutton" @click="showprojectdialog = false">Avbryt</v-btn>
            <v-btn class="smallbutton" color="secondary" :disabled="!validproject" @click="createproject">Skapa</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showdeleteprojectdialog" persistent max-width="500px">
      <v-card :loading="loading">
        <template slot="progress">
          <v-progress-linear color="red" indeterminate></v-progress-linear>
        </template>
        <v-card-title>
          <span class="headline">Ta bort projekt...</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            Alla mappar och filer tas bort.
            <br>Ok att fortsätta?
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="smallbutton" @click="showdeleteprojectdialog = false">Avbryt</v-btn>
          <v-btn class="smallbutton" color="secondary" @click="deleteproject()">Ta bort</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'
import { EventBus } from '@/store/event-bus.js'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      projectnumber: null,
      password: null,
      generatedefault: true,
      showprojectdialog: false,
      showdeleteprojectdialog: false,
      projectitems: [],
      selectedproject: {},

      loading: false,
      validproject: true,
      projectnumberRules: [
        v => !!v || 'Projektnummer måste anges',
        v => (v && v.length <= 50) || 'Projektnumret måste bestå av mindre än 50 tecken',
      ],
      passwordRules: [
        v => !!v || 'Lösenord måste anges',
        v => (v && v.length >= 1 && v.length <= 50) || 'Lösenordet måste bestå av minst 1 tecken eller mindre än 50 tecken',
      ],
    }
  },
  computed: {
    ...mapGetters([
      'isadmin',
      'isauthorized'
    ])
  },
  created: function () {
    if (this.isauthorized !== "undefined" && this.isauthorized !== undefined && this.isauthorized !== "") {
      this.getprojects()
    }
  },
  watch: {
    selectedproject(val) {
      this.getproject(val)
    }
  },
  methods: {
    getprojects(val) {
      HTTP.get('/project/getall/').then(response => {
        this.projectitems = response.data
        if (val) {
          this.projectitems.find((o) => {
            if (o.id == val) {
              this.selectedproject = o
            }
          })
        }
        else {
          this.selectedproject = this.projectitems && this.projectitems[0]
        }
        // }).catch(e => {
        //   this.$store.dispatch('showError', e.message)
      })
    },
    getproject(selectedproject) {
      EventBus.$emit('getproject', selectedproject)
      this.loading = false
    },
    shownewprojectdialog() {
      this.projectnumber = null
      this.password = null
      this.showprojectdialog = true
      this.$nextTick(() => {
        this.$refs.projectform.reset()
      })
      setTimeout(() => {
        this.$refs.projno.focus()
      }, 200)
    },

    createproject() {
      this.loading = true
      HTTP.post('/project/create', { projectnumber: this.projectnumber, password: this.password, generatedefault: this.generatedefault }).then(response => {
        this.loading = false
        this.showprojectdialog = false
        this.getprojects(response.data.id)
      }).catch(() => {
        this.loading = false
        this.showprojectdialog = false
        this.$store.dispatch('showError', 'Fel vid spara')
      })
    },
    deleteproject() {
      this.loading = true
      HTTP.post('/project/delete', { projectnumber: this.selectedproject.id }).then(() => {
        this.loading = false
        this.showdeleteprojectdialog = false
        this.getprojects()
      }).catch(() => {
        this.loading = false
        this.showdeleteprojectdialog = false
        this.$store.dispatch('showError', 'Fel vid ta bort projekt')

      })
    }
  }
}
</script>

<style>
.projectarea {
  margin-left: 0px !important;
}

.toolbarhome {
  height: 64px;
  background-color: black;
}

.v-toolbar__content {
  background: rgb(255, 187, 45);
  background: linear-gradient(45deg, rgba(255, 187, 45, 1) 0%, rgba(215, 84, 26, 1) 100%);
}

.toolbarcombo {
  height: 38px;
}

.logoutbutton {
  color: white !important;
  text-transform: none;
}

.logoutbutton>span>i {
  margin-left: 5px;
  font-size: 48px;
}

.logo {
  height: 68px;
}</style>