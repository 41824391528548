<template>
  <v-row>
    <v-overlay v-if="waitscreen"><v-progress-circular :size="70" :width="7" color="amber" indeterminate></v-progress-circular></v-overlay>
    <v-col cols="3" class="foldercontrol ma-0 pt-0 pr-0">
      <v-treeview :items="folderitems" item-value="id" item-key="name" class="treestyle" hoverable>
        <template v-slot:prepend="{ open }">
          <v-icon>
            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>
        </template>        
        <template slot="label" slot-scope="{ item }">
          <a @click="getfoldercontent(item.id)" style="width:100%;height:100%">{{ item.name }} {{ item.itemcount>0 ? "("+item.itemcount+")" : '' }}</a>
        </template>
      </v-treeview>
    </v-col>
    <v-col cols="9">
      <v-row class="mt-1 mb-4">
        <div class="breadcrumb d-flex align-center ml-3 flex-grow-1" >
          <template v-for="(item, index) in breadcrumbitems">
            <!-- eslint-disable-next-line -->
            <span v-if="index != 0" class="largetext" >/</span><a @click="getfoldercontent(item.id)" class="breadcrumbtext">{{ item.name }}</a>
          </template>
        </div>
        <div class="d-flex align-center ml-3 mr-4">
          <label for="file-upload"><span class="v-btn uploadbutton" :disabled="fileuploaddisabled"><v-icon>mdi-upload</v-icon>Ladda upp fil</span></label>
          <input v-show="showfiledialog" id="file-upload" type="file" @change="onFileChange">
          <v-btn class="projectbutton" :disabled="createfolderDisabled" @click="shownewfolder()"><v-icon>mdi-folder</v-icon>Skapa mapp</v-btn>
          <v-btn class="projectbutton" :disabled="filedownloadDisabled" @click="downloadItem()"><v-icon>mdi-download</v-icon>Ladda ner</v-btn>
          <v-btn class="projectbutton" :disabled="filedeletedisabled" @click="showdeletedialog=true"><v-icon>mdi-delete</v-icon>Ta bort</v-btn>
        </div>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="foldercontent"
        :footer-props="{'items-per-page-options': [100, 1000] }"
        :items-per-page="100"
        v-model="selectedfoldercontent"
        :loading="tableloading"
        show-select>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.name" @click="folderClick(item)">
              <td><v-checkbox class="checkboxrowclick" disabled v-model="selectedfoldercontent" :value="item" hide-details /></td>
              <td class="clickrow"><v-icon>{{ item.icon }}</v-icon></td>
              <td class="clickrow">{{ item.name }} {{item.itemcount>0 ? "("+item.itemcount+")" : ''}}</td>
              <td>{{ item.size | fixed }}</td>
              <td>{{ item.createddate | dateonly}}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <!-- <a ref="downloadlink" :href="downloadfilename" download>testing</a> -->
    </v-col>
    <v-dialog v-model="shownewfolderdialog" persistent max-width="500px">
      <v-card :loading="loading">
        <template slot="progress">
          <v-progress-linear color="red" indeterminate></v-progress-linear>
        </template>
        <v-form ref="newfolderform" v-model="validnewfolder">
          <v-card-title>
            <span class="headline">Skapa mapp</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="8">
                  <v-text-field label="Namn på mappen" v-model="foldername" :rules="nameRules" autofocus required></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="smallbutton" @click="shownewfolderdialog = false">Avbryt</v-btn>
          <v-btn class="smallbutton secondary" type="submit" :disabled="!validnewfolder" @click="createnewfolder()">Skapa</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
     <v-dialog v-model="showdeletedialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline">
          Bekräfta
        </v-card-title>
        <v-card-text>Ta bort valda dokument och/eller mappar?<br/>Operationen går inte att ångra.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="smallbutton" @click="showdeletedialog = false">Avbryt</v-btn>
          <v-btn class="smallbutton secondary"  @click="deletefolders()">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'
import { EventBus } from '@/store/event-bus.js'
import { b64toBlob } from '@/components/converters.js'
import { saveAs } from 'file-saver'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    projectname: null,
    projectid: null,
    selectedfolderid: null,
    folderitems: [],
    headers: [
      { text: 'Typ', value: 'type', width:'8%'},
      { text: 'Filnamn', value: 'name', width:'50%' },
      { text: 'Storlek', value: 'size' },
      { text: 'Uppladdningsdatum', value: 'createddate' },
    ],
    foldercontent: [],
    selectedfoldercontent: [],
    
    breadcrumbitems: [],
    showfiledialog: false,
    foldername: null,
    downloadfilename: null,
    shownewfolderdialog: false,
    showdeletedialog: false,
    loading: false,
    tableloading: false,
    waitscreen: false,
    validnewfolder: true,
    nameRules: [
      v => !!v || 'Mappnamnet måste anges',
      v => (v && v.length <= 100) || 'Mappnamnet måste bestå av mindre än 100 tecken',
    ],
    delay: 300,
    clicks: 0,
    timer: null
  }),
  computed: {
    ...mapGetters([
      'isadmin',
    ]), 
    fileuploaddisabled() {
        return !this.selectedfolderid
    },
    filedeletedisabled() {
        return this.selectedfoldercontent.length < 1 || !this.isadmin
    },
    filedownloadDisabled() {
      return this.selectedfoldercontent.length < 1
    },
    createfolderDisabled() {
      return this.projectid === null
    }
  },
  created () {
    // eslint-disable-next-line
    //console.log ('isadmin')
    // eslint-disable-next-line
    //console.log (this.isadmin)
  },
  mounted () {
    var self = this
    EventBus.$on('getproject', project => {
      if (project === undefined) {
        this.projectid = null
      } 
      self.resetfolders()
      self.projectname = project.name
      self.getfoldertree(project.id)
      self.projectid = project.id
    })
  },
  methods: {
    resetfolders () {
      this.folderitems = []
      this.selectedfolderid = null
      this.foldercontent = []
      this.selectedfoldercontent = []
      this.breadcrumbitems = []
    },
    getfoldertree (projectid) {
      HTTP.get('/document/getfoldertree/' + projectid)
        .then(response => {
          this.folderitems = response.data
          this.getfoldercontent(this.folderitems[0].id)
        })
        .catch(e => {
          this.$store.dispatch('showError', e.message)
        })      
    },
    getfoldercontent (id) {
      var self = this
      HTTP.get('/document/getfoldercontent/'+id)
        .then(response => {
          self.breadcrumbitems = response.data.breadcrumbitems
          self.foldercontent = response.data.folders
          if (self.foldercontent) {
            self.selectedfoldercontent = []
            self.selectedfolderid = id
            if (id) {
              self.selectedfolderid = id
            }
          }
        })
    },
    folderClick (item) {
      this.clicks++ 
      if(this.clicks === 1) {
        var self = this
        this.timer = setTimeout(function() {
          var current = self.foldercontent.find( f => f.id === item.id)
          if (self.selectedfoldercontent.find(s => s.id === item.id)) {
            self.selectedfoldercontent.pop(current)
          } else {
            self.selectedfoldercontent.push(current)
          }
          self.clicks = 0
        }, this.delay)
      } else {
        clearTimeout(this.timer);  
        this.clicks = 0
        if (item.documenttype === 1) {
          this.getfoldercontent(item.id)
        }
      }        	
    },  
    onFileChange (e) {
      this.waitscreen=true
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      for (var i = 0; i < files.length; i++) {
        this.createfile(files[i])
      }
      this.filename = null
      e.target.value = null
    },
    createfile (file) {
      var reader = new FileReader()
      reader.onload = (e) => {
        this.localfile = {
          filedata: btoa(e.target.result),
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
          folderid: this.selectedfolderid
        }
        this.saveattachement()
        file = null
      }
      reader.readAsBinaryString(file)
    },
    saveattachement () {
      var self = this
      HTTP.post('/document/savelocalfile', this.localfile)
        .then(() => {
          this.getfoldercontent(self.selectedfolderid)
          this.additemcount(self.selectedfolderid, 1)
        }).catch(e => {
          self.$store.dispatch('showError', e.response.data)
        }).then(() => {
          self.waitscreen = false
        })
    }, 
    shownewfolder () {
      this.foldername = null
      this.shownewfolderdialog=true
      this.$refs.newfolderform.reset()
      
    },
    createnewfolder () {
      this.shownewfolderdialog = false
      this.tableloading=true
      HTTP.post('/document/createfolder', { folderid: this.selectedfolderid, foldername: this.foldername }).then(response => {
        this.addtotree(response.data)
        this.getfoldercontent(this.selectedfolderid)
        this.tableloading=false
      }).catch(() => {
        this.tableloading=false
        this.$store.dispatch('showError', 'Fel vid skapa')
      })
    },
    downloadItem () {
      var self = this
      self.tableloading=true
      HTTP.post('/document/download', self.selectedfoldercontent ).then(response => {
        var stringfiledata = b64toBlob(response.data, '')
        var blob = new Blob([stringfiledata])

        const contentDispositionHeader = response.headers["content-disposition"]
        const contentDispositionHeaderResult = contentDispositionHeader.split(';')[1].trim().split('=')[1];
        const contentDispositionFileName = contentDispositionHeaderResult.replace(/"/g, '');
        saveAs(blob, contentDispositionFileName)

        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', 'file.pdf'); //or any other extension
        // window.document.body.appendChild(link);
        // link.click();

        // const blob = new Blob([response.data])
        // const url = URL.createObjectURL(response.data);
        // var link = window.document.createElement('a')
        // // eslint-disable-next-line
        // link.href = url
        // link.download = response.data.filename
        // link.click()
        // URL.revokeObjectURL(link.href)

        // eslint-disable-next-line
        //self.downloadfilename = process.env.VUE_APP_APIURL + '/zipfiles/' + response.data.filename
        //self.$refs.downloadlink.click()o

        //this.$refs.downloadlink.href = process.env.VUE_APP_APIURL + '/zipfiles/' + response.data.filename
        //link.download = response.data.filename
        
        //URL.revokeObjectURL(link.href)

      }).catch(e => {
        self.$store.dispatch('showError', 'Fel vid nerladdning:' + e)
      }).then(() => {
        self.tableloading=false
      })
    },
    deletefolders () {
      var self = this
      self.showdeletedialog = false
      self.tableloading=true
      HTTP.post('/document/delete', self.selectedfoldercontent ).then(() => {
        self.deletefromtree(self.selectedfoldercontent)
        self.additemcount(self.selectedfolderid, -self.selectedfoldercontent.length )
        self.getfoldercontent(self.selectedfolderid)
        self.tableloading=false
      }).catch(e => {
        self.tableloading=false
        self.$store.dispatch('showError', 'Fel vid radera:' + e.response.data)
      })

    },
    addtotree (newitem) {
      var found = false
      var parentfolder = null
      function findnoderecursive (folderid, currentfolder) {
        currentfolder.forEach(node => {
          if (!found) {
            if (node.id == folderid) {
              found = true
              parentfolder = node
            }
            if (!found) {
              findnoderecursive(folderid, node.children)
            }
          }
        })
      }

      findnoderecursive(newitem.parentId, this.folderitems)
      if (parentfolder) {
        var newfolder = {id: newitem.id, name: newitem.name, projectid: newitem.projectId, children: []}
        parentfolder.children.push(newfolder)
        parentfolder.itemcount += 1
      }
    },
    deletefromtree (deleteitems) {
      deleteitems.forEach( deleteitem => {
        function findnoderecursive (folderid, currentfolder) {
          currentfolder.forEach(node => {
            if (!found) {
              if (node.id == folderid) {
                found = true
                parentfolder = node
              }
              if (!found) {
                findnoderecursive(folderid, node.children)
              }
            }
          })
        }
        if (deleteitem.documenttype == 1) {
          var found = false
          var parentfolder = null
          findnoderecursive(deleteitem.parentid, this.folderitems)
          if (parentfolder) {
            for( var i = 0; i < parentfolder.children.length; i++) { 
              if ( parentfolder.children[i].id === deleteitem.id) { 
                parentfolder.children.splice(i, 1); 
                //parentfolder.itemcount -= 1
              }
            }

          }
        }
      })
    },
    additemcount (selectedfolderid, count) {
      var found = false
      var parentfolder = null
      function findnoderecursive (folderid, currentfolder) {
        currentfolder.forEach(node => {
          if (!found) {
            if (node.id == folderid) {
              found = true
              parentfolder = node
            }
            if (!found) {
              findnoderecursive(folderid, node.children)
            }
          }
        })
      }

      findnoderecursive(selectedfolderid, this.folderitems)
      if (parentfolder) {
        parentfolder.itemcount += count
      }
    },
  }
}

</script>

<style scoped>
  .foldercontrol {
    background-color:white; 
    height:90vh;
  }
  .folderheader {
    background-color: #F2F2F2;
    height: 50px;
    padding-top: 15px;
    padding-left: 20px;
  }
  .v-application--wrap {
    background-color: #F2F2F2 !important;
  }
  .breadcrumb {
    height: 58px;
  }
  .breadcrumb>i {
    font-size: 48px;
    color: #9F9F9F;
  }

  .breadcrumbtext {
    cursor: pointer;
    color: #9F9F9F;
    font-weight: bolder;
  }
  .largetext {
    font-size: 38px;
    margin-left: 10px;
    margin-right: 10px;
    color: #9F9F9F;
  }
  .uploadbutton {
    text-transform: none !important;
    height: 36px !important;
    background-color: white;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size:14px;
    font-weight: bolder;
    color: #7D7D7D;
    cursor:pointer;
  }
  .uploadbutton[disabled] {
    color: #7D7D7D;
    background-color: #D5D5D5;
    box-shadow: 0px 0px 0px 0px;
  }
  .uploadbutton[disabled]>i {
    color: #9E9E9E;
  }
  .clickrow {
    cursor:pointer;
  }
  .checkboxrowclick {
    margin-top:0px
  }
.treestyle {
  height:100%;
  overflow-y: scroll;
}


</style>