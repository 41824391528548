<!--eslint-disable-next-line-->
<template>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'

export default {
  name: 'Logout',
  created () {
    HTTP.defaults.headers.common['Authorization'] = null
    this.$store.dispatch('deleteAuthorization')
    this.$router.push('/login')
  }
}

</script>
