import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './assets/app.scss'
import vuemoment from 'vue-moment'
//import PlanixDataTable from './components/PlanixDataTable.vue'

Vue.config.productionTip = false

Vue.use(vuemoment)

Vue.filter('fixed', function (value) {
  if (!value && value !== 0) return ''
  return value.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
})

Vue.filter('dateonly', function (value) {
  if (value) {
    return Vue.moment(value).format('YYYY-MM-DD')
  }
  return null
})

Vue.filter('short', function (value) {
  return Vue.moment(value).format('YYYY-MM-DD HH:mm')
})

Vue.filter('long', function (value) {
  return Vue.moment(value).format('D MMMM YYYY HH:mm')
})

Vue.filter('time', function (value) {
  return Vue.moment(value).format('HH:mm')
})

//Vue.component('PlanixDataTable', PlanixDataTable)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
