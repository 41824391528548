<template>
  <v-app>
    <router-view></router-view>
    <v-dialog v-model="errordialog" persistent width="500">
      <v-card class="pa-4">
          <v-card-title class="headline">Message</v-card-title>
          <v-card-text><v-icon large color="red">mdi-alert-circle</v-icon> {{errormessage}}</v-card-text>
          <v-card-actions class="justify-center">
            <v-btn tile @click="clearerror()">Ok</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-app>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'

export default {
  name: 'App',

  components: {
  },
  data () {
    return {
    }
  }, 
  created () {
    this.checkCurrentLogin()
    this.$store.dispatch('initStorage')
  },
  computed: {
    ...mapGetters([
      'errordialog',
      'errormessage',
      'isauthorized',
      'bearertoken',      
    ])
  },
  methods: {
    checkCurrentLogin () {
      if (HTTP.defaults.headers.common['Authorization'] === undefined) {
        HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + this.bearertoken
      }
      HTTP.get('/account/checkauthorization')
        .catch(e => {
          this.$store.dispatch('initStorage')
          this.error = e
          this.$router.push('/login')
        })
    },       
    clearerror () {
      this.$store.dispatch('clearError')
    }
  }
}
</script>
<style>

</style>