<template>
  <v-container fill-height>
    <v-row class="justify-center">
      <v-card class="info rounded-lg" :loading="loading">
        <template slot="progress">
          <v-progress-linear color="red" indeterminate></v-progress-linear>
        </template>
        <v-form class="pt-10 px-10">
          <v-row class="d-flex justify-center">
              <img src="./../assets/PlanixLogo.svg" class="ma-5"/>
          </v-row>
          <v-row class="mt-10">
            <v-text-field
              v-model="userinfo.username"
              label="Projektnummer"
              name="login"
              type="text"
              solo
              v-on:keyup="checkreturn"
              class="mx-5"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              v-model="userinfo.password"
              id="password"
              label="Lösenord"
              name="password"
              type="password"
              solo
              v-on:keyup="checkreturn"
              class="mx-5"
            ></v-text-field>          
          </v-row>
          <v-row class="justify-center">
            <v-btn class="normalbutton mt-3" dark large @click="login()">Login</v-btn>
          </v-row>
          <v-row class="justify-center mt-3">
            <div class="errortext">{{errortext}}</div>
          </v-row>
          <v-row class="justify-center">
            <div class="logotext mt-10 mb-2">@2020 BjKS AB</div>
            
          </v-row>

        </v-form>
        
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { HTTP } from '@/plugins/backendAPI'

export default {
  name: 'Login',
  data () {
    return {
      userinfo: {
        username: '',
        password: ''
      },
      loading: false,
      errortext: ''
    }
  },
  methods: {
    checkreturn (e) {
      if (e.keyCode === 13) {
        this.login()
      }
    },
    login () {
      this.errortext = ''
      this.loading = true
      var params = 'username=' + this.userinfo.username + '&password=' + encodeURIComponent(this.userinfo.password)
      delete HTTP.defaults.headers.common['Authorization']
      // eslint-disable-next-line
      axios({ method: 'post', url: process.env.VUE_APP_APIURL + '/token', data: params, headers: { 'accept': 'application/json', 'content-type': 'application/x-www-form-urlencoded' } })
        .then(response => {
          this.loginSuccessful(response)
        })
        .catch(ex => {
          this.loginFailed(ex)
        })
    },
    loginSuccessful (req) {
      if (!req.data) {
        this.loginFailed()
        return
      }
      if (HTTP.defaults.headers.common['Authorization'] === undefined) {
        HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + req.data
      }
      HTTP.post('/account/getuser', { username: this.userinfo.username })
        .then(response => {
          this.$store.dispatch('saveAuthorization', {username: this.userinfo.username, isauthorized: true, isadmin: response.data.usertype==1 ? true:false, bearertoken: req.data})
          this.$router.push({ path: '/project' })
          this.loading = false
        })
        .catch(ex => {
          this.errortext = ex
          this.loading = false
        })
    },
    loginFailed () {
      this.loading = false
      this.errortext = "Fel projektnummer eller lösenord"
      this.$store.dispatch('deleteAuthorization')
    },
  }
}

</script>

<style lang="css" scoped>

  .logotext {
    font-size: 11px;
    color: darkgray;
  }
</style>
