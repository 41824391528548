import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#000',
            secondary: '#F5A229',
            accent: '#82B1FF',
            error: '#FF5252',
            info: '#F2F2F2',
            success: '#4CAF50',
            warning: '#FFC107',
          },
        },
    },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    }
});

Vue.use(Vuetify);
